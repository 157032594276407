import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';
import { createWrapper } from 'next-redux-wrapper';

import { rootReducer } from './state';

const middleware = [thunkMiddleware];

const makeConfiguredStore = reducer => createStore(reducer, undefined, applyMiddleware(...middleware));

const makeStore = () => {
    const isServer = typeof window === 'undefined';

    if (isServer) {
        return makeConfiguredStore(rootReducer);
    } else {
        const persistConfig = {
            transforms: [immutableTransform()],
            key: 'root',
            storage,
            whitelist: [],
        };

        const persistedReducer = persistReducer(persistConfig, rootReducer);

        const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
        store.__persistor = persistStore(store);

        return store;
    }
};

const wrapper = createWrapper(makeStore);

export { wrapper };
