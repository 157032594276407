import { internalInstance } from '../../utils/httpinstance';

export const ADD_FILTER = 'ADD_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const GET_PEOPLE_START = 'GET_PEOPLE_START';
export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';
export const GET_PEOPLE_ERROR = 'GET_PEOPLE_ERROR';

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchPeoples = (page, filters, sort) => {
    return async dispatch => {
        dispatch({ type: GET_PEOPLE_START });
        await internalInstance
            .get(`/api/peoples?page=${page}&filters=${filters}&sort=${sort}`)
            .then(res => {
                dispatch({
                    type: GET_PEOPLE_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_PEOPLE_ERROR });
            });
    };
};
