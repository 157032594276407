import { internalInstance } from '../../utils/httpinstance';

export const GET_PAGE_MODAL_START = 'GET_PAGE_MODAL_START';
export const GET_PAGE_MODAL_SUCCESS = 'GET_PAGE_MODAL_SUCCESS';
export const GET_PAGE_MODAL_ERROR = 'GET_PAGE_MODAL_ERROR';

export const getPage = (path, lang, preview, pageId) => {
    return async dispatch => {
        dispatch({ type: GET_PAGE_MODAL_START });
        await internalInstance
            .get(`/api/page?path=${path}&lang=${lang}&preview=${preview}&pageId=${pageId}`)
            .then(res => {
                dispatch({
                    type: GET_PAGE_MODAL_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_PAGE_MODAL_ERROR });
            });
    };
};
