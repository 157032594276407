import { internalInstance } from '../../utils/httpinstance';

export const ADD_PORT_FILTER = 'ADD_PORT_FILTER';
export const CLEAR_PORT_FILTERS = 'CLEAR_PORT_FILTERS';
export const GET_PORTFOLIO_START = 'GET_PORTFOLIO_START';
export const GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_ERROR = 'GET_PORTFOLIO_ERROR';

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_PORT_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_PORT_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchPortfolio = (page, filters, sort) => {
    return async dispatch => {
        dispatch({ type: GET_PORTFOLIO_START });
        await internalInstance
            .get(`/api/portfolio?page=${page}&filters=${filters}&sort=${sort}`)
            .then(res => {
                dispatch({
                    type: GET_PORTFOLIO_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_PORTFOLIO_ERROR });
            });
    };
};
