import { fromJS, Set, Map } from 'immutable';

import { ADD_FILTER, CLEAR_FILTERS, GET_NEWS_START, GET_NEWS_SUCCESS, GET_NEWS_ERROR } from './news.action';

const initialState = fromJS({
    filters: new Set(),
    news: {},
    newsInit: false,
    newsLoading: false,
});

export const news = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(f => f.type === filter.type && f.value === filter.value);
                if (thisFilter.length > 0) {
                    ctx.set('filters', filters.delete(thisFilter[0]));
                } else {
                    ctx.set('filters', filters.add(filter));
                }
            });
        case CLEAR_FILTERS:
            return state.withMutations(ctx => {
                const { type } = action.payload;
                const filters = ctx.get('filters');
                if (type === '') {
                    ctx.set('filters', new Set());
                } else {
                    const newFilters = Array.from(filters).filter(f => f.type !== type);
                    ctx.set('filters', new Set(newFilters));
                }
            });
        case GET_NEWS_START:
            return state.withMutations(ctx => {
                ctx.set('newsInit', true);
                ctx.set('newsLoading', true);
                ctx.set('news', new Map({}));
            });
        case GET_NEWS_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('newsLoading', false);
                ctx.set('news', new Map(action.payload.data));
            });
        case GET_NEWS_ERROR:
            return state.withMutations(ctx => {
                ctx.set('newsLoading', true);
                ctx.set('news', new Map({}));
            });
        default:
            return state;
    }
};
