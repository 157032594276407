import { internalInstance } from '../../utils/httpinstance';

export const ADD_FILTER = 'ADD_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchNews = (path, page, sort, filters) => {
    return async dispatch => {
        dispatch({ type: GET_NEWS_START });
        await internalInstance
            .get(`/api/news?path=${path}&page=${page}&sort=${sort}&filters=${filters}`)
            .then(res => {
                dispatch({
                    type: GET_NEWS_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_NEWS_ERROR });
            });
    };
};
