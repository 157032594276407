import { fromJS, Set, Map } from 'immutable';

import { ADD_FILTER, CLEAR_FILTERS, GET_PEOPLE_START, GET_PEOPLE_SUCCESS, GET_PEOPLE_ERROR } from './people.action';

const initialState = fromJS({
    filters: new Set(),
    peoples: {},
    peoplesInit: false,
    peoplesLoading: false,
});

export const people = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(f => f.type === filter.type && f.value === filter.value);
                if (thisFilter.length > 0) {
                    ctx.set('filters', filters.delete(thisFilter[0]));
                } else {
                    ctx.set('filters', filters.add(filter));
                }
            });
        case CLEAR_FILTERS:
            return state.withMutations(ctx => {
                const { type } = action.payload;
                const filters = ctx.get('filters');
                if (type === '') {
                    ctx.set('filters', new Set());
                } else {
                    const newFilters = Array.from(filters).filter(f => f.type !== type);
                    ctx.set('filters', new Set(newFilters));
                }
            });
        case GET_PEOPLE_START:
            return state.withMutations(ctx => {
                ctx.set('peoplesInit', true);
                ctx.set('peoplesLoading', true);
                ctx.set('peoples', new Map({}));
            });
        case GET_PEOPLE_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('peoplesLoading', false);
                ctx.set('peoples', new Map(action.payload.data));
            });
        case GET_PEOPLE_ERROR:
            return state.withMutations(ctx => {
                ctx.set('peoplesLoading', true);
                ctx.set('peoples', new Map({}));
            });
        default:
            return state;
    }
};
