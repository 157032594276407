import { fromJS, Set, Map } from 'immutable';

import {
    ADD_PORT_FILTER,
    CLEAR_PORT_FILTERS,
    GET_PORTFOLIO_START,
    GET_PORTFOLIO_SUCCESS,
    GET_PORTFOLIO_ERROR,
} from './portfolio.action';

const initialState = fromJS({
    filters: new Set(),
    portfolio: {},
    portfolioInit: false,
    portfolioLoading: false,
});

export const portfolio = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PORT_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(f => f.type === filter.type && f.value === filter.value);
                if (thisFilter.length > 0) {
                    ctx.set('filters', filters.delete(thisFilter[0]));
                } else {
                    ctx.set('filters', filters.add(filter));
                }
            });
        case CLEAR_PORT_FILTERS:
            return state.withMutations(ctx => {
                const { type } = action.payload;
                const filters = ctx.get('filters');
                if (type === '') {
                    ctx.set('filters', new Set());
                } else {
                    const newFilters = Array.from(filters).filter(f => f.type !== type);
                    ctx.set('filters', new Set(newFilters));
                }
            });
        case GET_PORTFOLIO_START:
            return state.withMutations(ctx => {
                ctx.set('portfolioInit', true);
                ctx.set('portfolioLoading', true);
                ctx.set('portfolio', new Map({}));
            });
        case GET_PORTFOLIO_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('portfolioLoading', false);
                ctx.set('portfolio', new Map(action.payload.data));
            });
        case GET_PORTFOLIO_ERROR:
            return state.withMutations(ctx => {
                ctx.set('portfolioLoading', true);
                ctx.set('portfolio', new Map({}));
            });
        default:
            return state;
    }
};
